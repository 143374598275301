// @flow
import React from 'react'
import Layout from 'containers/Layout'
import Story from 'storybook/Story'
import { graphql } from 'gatsby'
import Meta from 'theme/atoms/Meta'

type Props = {
  location: {
    key: string
    pathname: string
  }
  data: {
    staticBlock: {
      story: any
    }
  }
}

export default (props: Props) => {
  const { story } = props.data.staticBlock

  const crumbs = [{ label: 'Startseite', link: '/' }, { label: '500' }]

  return (
    <Layout partialStateUpdates={story.partialStateUpdates} breadcrumbs={crumbs}>
      <Meta
        type="website"
        pathname={props.location.pathname}
        title="Application Error"
        description="Es ist ein Fehler passiert"
      />
      <h1 style={{ textAlign: 'center' }}>
        Wir sind verwundert - Leider ist ein Fehler aufgetreten (500)
      </h1>
      <Story key={props.location.key} story={story} />
    </Layout>
  )
}

export const query = graphql`
  query {
    staticBlock(identifier: { eq: "500_Application_Error" }) {
      story
    }
  }
`
